import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, mergeMap, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
	serverUrl = environment.serverUrl;
	constructor(private user: UserService) {}
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return this.user.getToken().pipe(
			mergeMap((token) => {
				req = req.clone({
					withCredentials: true,
					headers: req.headers
						.set('Authorization', `Bearer ${token}`)
						.set('Content-Type', 'application/json')
						.set('Accept', 'application/json'),
				});

				if (!req.url.includes('i18n')) {
					req = req.clone({ url: this.serverUrl + req.url });
				}

				return next.handle(req).pipe(
					map((event: HttpEvent<any>) => {
						// if (event instanceof HttpResponse) {
						// 	// console.info(event);
						// }
						return event;
					})
				);
			})
		);
	}
}
