import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BoliApiService {
	constructor(private server: HttpClient) {}

	/* ==================== USER ==================== */
	signIn(data: {}): Observable<any> {
		return this.server.post('/user/sign-in', data);
	}

	signOut(): Observable<any> {
		return this.server.post('/user/sign-out', {});
	}

	updateUser(data): Observable<any> {
		return this.server.patch(`/user`, data);
	}

	getUser(id, params?): Observable<any> {
		return this.server.get(`/user/${id}`, { params });
	}

	/**
	 *
	 * @param params an array of mongodb style select.
	 *
	 * 	Example 1: ['firstName', 'age', 'distance'] only include these field
	 *
	 * 	Example 2: ['-firstName', '-age', '-distance'] exclude these field
	 *
	 * 	Both styles, inclusion and exlusion can be combine in a single query
	 *
	 * 	if no params are passed the whole document will be returned
	 *
	 */
	getCurrentUser(params?): Observable<any> {
		return this.server.get('/user', { params });
	}

	getSettings(): Observable<any> {
		return this.server.get('/user/settings');
	}

	getSetting(params): Observable<any> {
		return this.server.get('/user/setting', { params });
	}

	listPotentialUsers(quantity): Observable<any> {
		return this.server.get('/user/potential-matches', { params: { quantity } });
	}

	listLiked(): Observable<any> {
		return this.server.get('/user/liked');
	}

	listChats(): Observable<any> {
		return this.server.get('/user/chat');
	}

	getChat(chatId): Observable<any> {
		return this.server.get(`/user/chat/${chatId}`);
	}

	getUnreadChatsCount(): Observable<any> {
		return this.server.get(`/user/chat/count`);
	}

	unmatchUser(chatId, data): Observable<any> {
		return this.server.post(`/user/unmatch/${chatId}`, data);
	}

	listMatches(): Observable<any> {
		return this.server.get('/user/match');
	}

	getMatch(id: string): Observable<any> {
		return this.server.get(`/user/match/${id}`);
	}

	verifyPhoneNumber(phoneNumber): Observable<any> {
		return this.server.post('/user/verify-phone', phoneNumber);
	}

	verifyCode(verificationCode): Observable<any> {
		return this.server.post('/user/verify-code', verificationCode);
	}

	resendVerificationCode(): Observable<any> {
		return this.server.post('/user/resend-verification-code', {});
	}

	accountRecovery(email): Observable<any> {
		return this.server.post('/user/account-recovery', email);
	}

	like(id, action?): Observable<any> {
		return this.server.post(`/user/like/${id}`, { action });
	}

	getLikeCount(): Observable<any> {
		return this.server.get(`/user/like/count`);
	}

	pass(id): Observable<any> {
		return this.server.post(`/user/pass/${id}`, null);
	}

	deleteAccount(data?): Observable<any> {
		return this.server.post('/user/delete-account', data);
	}

	listInterests(): Observable<any> {
		return this.server.get('/core/interests');
	}

	getSubscription(id, data?): Observable<any> {
		return this.server.get(`/core/subscriptions/${id}`, data);
	}

	listSubscriptions(): Observable<any> {
		return this.server.get('/core/subscriptions');
	}

	reportUser(data: any): Observable<any> {
		return this.server.post('/user/report', data);
	}

	listUserReportReasons(params?): Observable<any> {
		return this.server.get('/core/report-unmatch-reasons', { params });
	}

	getSocketToken(namespace): Observable<any> {
		return this.server.get(`/core/get-socket-token/${namespace}`);
	}
}
