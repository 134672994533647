import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatform } from '@ionic/core';
import { from, map, Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BoliApiService } from './boli-api.service';
import { StorageService } from './storage.service';

const TOKEN = 'TOKEN';
const CURRENT_USER = 'USER';
const COOKIE = 'COOKIE';
const THEME = 'THEME';
const LANGUAGE = 'LANGUAGE';
const FCM = 'FCM';
const CHATID = 'CHAT_ID';
const CHATUSERID = 'CHAT_USER_ID';
const EMOJI_GROUPED = 'EMOJI_GROUPED';
const HAPTICS = 'HAPTICS';
const ENTER_TO_SEND = 'ENTER_TO_SEND';
const IS_DARK_MODE = 'IS_DARK_MODE';
const SOCKET_TOKEN = 'SOCKET_TOKEN';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	subscriptions$: any[] = [];
	constructor(
		private storage: StorageService,
		private api: BoliApiService,
		private router: Router
	) {
		const sub = this.getEmojiLayout()
			.pipe()
			.subscribe((grouped) => {
				if (!grouped) {
					this.setEmojiLayout();
				}
			});

		this.subscriptions$.push(sub);

		this.setHaptics();
	}

	async setCookie(cookie: string) {
		return from(this.storage?.set(COOKIE, cookie));
	}

	getCookie() {
		return from(this.storage?.get(COOKIE));
	}

	getEnterToSend() {
		return from(this.storage?.get(ENTER_TO_SEND));
	}

	setEnterToSend(value: boolean = true) {
		return from(this.storage?.set(ENTER_TO_SEND, value));
	}

	signOut() {
		return new Observable((obs) => {
			const sub = this.api
				.signOut()
				.pipe()
				.subscribe(() => {
					this.storage?.clear();
					this.router.navigateByUrl('/');
					obs.next();
				});

			this.subscriptions$.push(sub);
			return sub;
		});
	}

	deleteAccount() {
		return this.api
			.deleteAccount()
			.pipe(switchMap((res) => this.signOut().pipe()));
	}

	setEmojiLayout(grouped: boolean = true) {
		return from(this.storage?.set(EMOJI_GROUPED, grouped));
	}

	getEmojiLayout() {
		return from(this.storage?.get(EMOJI_GROUPED));
	}

	getAge(date: string) {
		const today = new Date();
		const birthDate = new Date(date);
		let age = today.getFullYear() - birthDate.getFullYear();
		const m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	isLegalAge(dob: string) {
		return this.getAge(dob) >= environment.minLegalAge;
	}

	setToken(token: string) {
		return from(this.storage?.set(TOKEN, token));
	}

	setUser(user) {
		delete user.updated;
		return from(this.storage?.set(CURRENT_USER, user));
	}

	updateUser(update) {
		const sub = this.getUser()
			.pipe()
			.subscribe((user) => {
				this.setUser(user ? { ...user, ...update } : update);
			});

		this.subscriptions$.push(sub);
	}

	getUser() {
		return from(this.storage?.get(CURRENT_USER));
	}

	getToken() {
		return from(this.storage?.get(TOKEN));
	}

	setChatId(id) {
		return from(this.storage?.set(CHATID, id));
	}

	getChatId() {
		return from(this.storage?.get(CHATID));
	}

	setChatUserId(id) {
		return from(this.storage?.set(CHATUSERID, id));
	}

	getChatUserId() {
		return from(this.storage?.get(CHATUSERID));
	}

	clearStorage() {
		return this.storage.clear();
	}

	public isSignedIn() {
		return this.getToken().pipe(
			map((token) => {
				return token ? true : false;
			}),
			switchMap((signedIn) => {
				return this.getUser().pipe(
					map((user) => {
						return user ? user?.completedProfile && signedIn : signedIn;
					})
				);
			})
		);
	}

	async setHaptics(value: boolean = true) {
		this.storage?.set(HAPTICS, value);
	}

	getHaptics() {
		return from(this.storage?.get(HAPTICS));
	}

	toggleDarkMode(isDark: boolean = true) {
		document.body.setAttribute('color-theme', isDark ? 'dark' : 'light');
	}

	setDarkMode(value: boolean = true) {
		this.toggleDarkMode(value);
		return from(this.storage?.set(IS_DARK_MODE, value));
	}

	getDarkMode() {
		return from(this.storage?.get(IS_DARK_MODE));
	}

	setTheme(opts?: any) {
		if (isPlatform('capacitor')) {
			const overlay: boolean = opts?.overlay || false;
			const style: string = opts?.style;
			let color: string = opts?.color;

			const sub = this.getDarkMode().subscribe((isDarkMode: boolean) => {
				import('@capacitor/status-bar').then(({ StatusBar, Style }) => {
					if (style) {
						if (style === 'dark') {
							StatusBar.setStyle({ style: Style.Dark });
						} else {
							StatusBar.setStyle({ style: Style.Light });
						}
					} else {
						if (isDarkMode) {
							StatusBar.setStyle({ style: Style.Dark });
						} else {
							StatusBar.setStyle({ style: Style.Light });
						}
					}

					if (color) {
						if (color === '#FFFFFF' && isDarkMode) {
							color = '#000000';
						} else if (color === '#f4f5f8' && isDarkMode) {
							color = '#1a1a1a';
						}

						StatusBar.setBackgroundColor({ color });
					}
					StatusBar.setOverlaysWebView({ overlay });
				});
			});

			this.subscriptions$.push(sub);
		}
	}

	public getLanguage() {
		return from(this.storage?.get(LANGUAGE));
	}

	public setLanguage(language: string = 'system') {
		if (language === null || language === undefined || language === 'system') {
			return from(this.storage?.remove(LANGUAGE));
		} else {
			const sub = this.api.updateUser({ lang: language }).pipe().subscribe();
			this.subscriptions$.push(sub);
			return from(this.storage?.set(LANGUAGE, language));
		}
	}

	setFcmToken(token: string) {
		const sub = this.api.updateUser({ fcmToken: token }).pipe().subscribe();
		this.subscriptions$.push(sub);
		return from(this.storage?.set(FCM, token));
	}

	getFcmToken() {
		return from(this.storage?.get(FCM));
	}

	getSocketToken(namespace) {
		return from(
			this.storage?.get(
				SOCKET_TOKEN + '_' + namespace.toUpperCase().replace('-', '_')
			)
		);
	}

	setSocketToken(data: any) {
		return from(
			this.storage?.set(
				SOCKET_TOKEN + '_' + data.namespace.toUpperCase().replace('-', '_'),
				data.token
			)
		);
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((sub) => {
			sub.unsubscribe();
		});
	}
}
