import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PushNotificationsPlugin } from '@capacitor/push-notifications';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BoliApiService } from './boli-api.service';
import { CoreService } from './core.service';
import { UserService } from './user.service';

enum NotificationType {
	MATCH = 'MATCH',
	MATCHS = 'MATCHS',
	CHAT = 'CHAT',
	CHATS = 'CHATS',
	ERROR = 'ERROR',
}

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	private subscriptions$: any[] = [];
	constructor(
		private router: Router,
		private api: BoliApiService,
		private user: UserService,
		private core: CoreService
	) {}

	initPush() {
		import('@ionic/core').then(({ isPlatform }) => {
			if (isPlatform('capacitor')) {
				this.registerNotifications();
			}
		});
	}

	private addListeners(pushNotifications: PushNotificationsPlugin) {
		pushNotifications.addListener('registration', async (token) => {
			const laoder = await this.core.presentLoader();

			const sub = this.user
				.setFcmToken(token.value)
				.pipe(
					catchError(() => {
						this.core.presentToast('Somthing went wrong');

						return EMPTY;
					})
				)
				.subscribe(() => {
					laoder.dismiss();
				});

			this.subscriptions$.push(sub);
		});

		pushNotifications.addListener('registrationError', (error: any) => {
			this.core.presentToast('Somthing went wrong');
		});

		pushNotifications.addListener(
			'pushNotificationReceived',
			async (notification) => {
				console.info('Push Received: ', notification);
			}
		);

		pushNotifications.addListener(
			'pushNotificationActionPerformed',
			async (notification) => {
				const data = notification.notification.data;
				console.info('Action performed: ', notification);

				switch (data.type) {
					case NotificationType.CHAT || NotificationType.MATCH:
						this.router.navigate(['app', 'message', 'chat'], {
							state: { chatId: data.chatId },
						});
						break;
					case NotificationType.CHATS || NotificationType.MATCHS:
						this.router.navigateByUrl('app/message');
						break;
					case NotificationType.ERROR:
						this.router.navigate(['app', 'message', 'chat'], {
							state: { chatId: data.chatId },
						});
						break;

					default:
						this.router.navigate(['app', 'message', 'chat'], {
							state: { chatId: data.chatId },
						});
						break;
				}
			}
		);
	}

	private registerNotifications() {
		import('@capacitor/push-notifications').then(
			async ({ PushNotifications }) => {
				let permStatus = await PushNotifications.checkPermissions();

				if (permStatus.receive === 'prompt') {
					permStatus = await PushNotifications.requestPermissions();
				}

				if (permStatus.receive !== 'granted') {
					throw new Error('User denied permissions!');
				}

				PushNotifications.register();

				this.addListeners(PushNotifications);
			}
		);
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((sub) => {
			sub.unsubscribe();
		});
	}
}
