import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { catchError, EMPTY, map, Observable } from 'rxjs';
import { User } from '../../types/user';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class NavigationGuard implements CanLoad {
	constructor(private user: UserService, private router: Router) {}

	canLoad(
		route: Route,
		segments: UrlSegment[]
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.user.getUser().pipe(
			catchError(() => {
				this.router.navigateByUrl('/');
				return EMPTY;
			}),
			map((user: User) =>
				!user?.completedProfile ? true : this.router.parseUrl('/app')
			)
		);
	}
}
