import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth.guard';
import { NavigationGuard } from './core/services/navigation.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () =>
			import('./home/home.module').then((m) => m.HomePageModule),
	},
	{
		path: 'get-started',
		children: [
			{
				path: 'phone-number',
				canLoad: [NavigationGuard],
				loadChildren: () =>
					import('./get-started/phone-number/phone-number.module').then(
						(m) => m.PhoneNumberModule
					),
			},
			{
				path: 'phone-number-verification',
				canLoad: [AuthGuard, NavigationGuard],
				loadChildren: () =>
					import(
						'./get-started/phone-number-verification/phone-number-verification.module'
					).then((m) => m.PhoneNumberVerificationModule),
			},
			{
				path: 'email',
				canLoad: [AuthGuard, NavigationGuard],
				loadChildren: () =>
					import('./get-started/email/email.module').then((m) => m.EmailModule),
			},
			{
				path: 'agreement',
				canLoad: [AuthGuard, NavigationGuard],
				loadChildren: () =>
					import('./get-started/agreement/agreement.module').then(
						(m) => m.AgreementModule
					),
			},
			{
				path: 'user-info',
				canLoad: [AuthGuard, NavigationGuard],
				loadChildren: () =>
					import('./get-started/user-info/user-info.module').then(
						(m) => m.UserInfoModule
					),
			},
			{
				path: 'photo',
				canLoad: [AuthGuard, NavigationGuard],
				loadChildren: () =>
					import('./get-started/photo/photo.module').then((m) => m.PhotoModule),
			},
			{
				path: 'welcome',
				canLoad: [AuthGuard, NavigationGuard],
				loadChildren: () =>
					import('./get-started/welcome/welcome.module').then(
						(m) => m.WelcomeModule
					),
			},
		],
	},
	{
		path: 'app',
		canLoad: [AuthGuard],
		loadChildren: () =>
			import('./tabs/tabs.module').then((m) => m.TabsPageModule),
	},
	{
		path: 'safety',
		canLoad: [AuthGuard],
		loadChildren: () =>
			import('./safety/safety.module').then((m) => m.SafetyPageModule),
	},
	{
		path: '**',
		redirectTo: '',
	},
];
@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
