import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { catchError, EMPTY, map, Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanLoad {
	excludedRoutes: any[] = [
		'phone-number-verification',
		'photo',
		'user-info',
		'welcome',
		'agreement',
		'email',
	];
	constructor(private user: UserService, private router: Router) {}
	canLoad(
		route: Route,
		segments: UrlSegment[]
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.user.isSignedIn().pipe(
			catchError(() => {
				this.router.navigateByUrl('/');
				return EMPTY;
			}),
			map((isSignedIn) => {
				if (!isSignedIn) {
					isSignedIn = this.excludedRoutes.includes(route.path);
				}

				return isSignedIn ? true : this.router.parseUrl('/');
			})
		);
	}
}
