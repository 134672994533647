import { isPlatform } from '@ionic/angular';
const server = isPlatform('capacitor') ? 'https://www.bolilove.com' : '';

export const environment = {
	appName: 'Boli',
	production: true,
	serverUrl: `${server}/api/v1`,
	server,
	signUpSteps: 4,
	phoneNumberLength: 10,
	minLegalAge: 18,
	toastDuration: 3000,
	cookiesPolicyLink: 'https://www.bolilove.com/cookies-policy',
	privacyPolicyLnk: 'https://www.bolilove.com/privacy-policy',
	paidFeatures: ['showMyAge', 'showMyDistance', 'showMyProfile'],
	socketServer: {
		chat: server + '/chat',
		appUpdate: server + '/app-update',
		messageUpdate: server + '/message-update',
	},
};
