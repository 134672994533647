import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { IonRouterOutlet, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY } from 'rxjs';
import { BoliApiService } from './core/services/boli-api.service';
import { CoreService } from './core/services/core.service';
import { NotificationService } from './core/services/notification.service';
import { UserService } from './core/services/user.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
	showSpash: boolean = true;
	backButton: any;
	timeout: any;
	@ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
	subscriptions$: any[] = [];
	constructor(
		private user: UserService,
		private core: CoreService,
		private platform: Platform,
		private translate: TranslateService,
		private router: Router,
		private api: BoliApiService,
		private notification: NotificationService
	) {
		this.initializeApp();
	}

	ngAfterViewInit() {
		let backCount = 0;
		document.addEventListener('ionBackButton', (ev: any) => {
			ev.preventDefault();
			ev.stopPropagation();
			backCount++;

			ev.detail.register(-1, () => {
				clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					backCount = 0;
				}, 1000);

				if (!this.routerOutlet.canGoBack() && backCount === 2) {
					App.exitApp();
				}
			});
		});
	}

	loadDefaultSettings() {
		this.setLanguage();
		const sub = this.user
			.getDarkMode()
			.pipe(
				catchError(() => {
					this.core.presentToast('Somthing went wrong');

					return EMPTY;
				})
			)
			.subscribe((isDarkMode) => {
				let s: any;
				if (isDarkMode !== null) {
					s = this.user.setDarkMode(isDarkMode).subscribe(() => {
						this.user.setTheme();
					});
				} else {
					const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
					s = this.user.setDarkMode(prefersDark.matches).subscribe(() => {
						this.user.setTheme();
					});

					prefersDark.addEventListener('change', (mediaQuery) => {
						const sbs = this.user
							.setDarkMode(mediaQuery.matches)
							.subscribe(() => {
								this.user.setTheme();
							});
						this.subscriptions$.push(sbs);
					});
				}
				this.subscriptions$.push(s);
			});

		this.subscriptions$.push(sub);
	}

	setLanguage() {
		const sub = this.user
			.getLanguage()
			.pipe(
				catchError(() => {
					this.core.presentToast('Somthing went wrong');

					return EMPTY;
				})
			)
			.subscribe((language) => {
				if (
					language === null ||
					language === undefined ||
					language === 'system'
				) {
					const browserLang = this.translate.getBrowserLang();
					this.translate.use(browserLang);
					const s = this.user
						.isSignedIn()
						.pipe(
							catchError(() => {
								this.core.presentToast('Somthing went wrong');

								return EMPTY;
							})
						)
						.subscribe((signedIn) => {
							if (signedIn) {
								const s = this.api
									.updateUser({ lang: browserLang })
									.pipe()
									.subscribe();
								this.subscriptions$.push(s);
							}
						});

					this.subscriptions$.push(s);
				} else {
					this.translate.use(language);
				}
			});

		this.subscriptions$.push(sub);
	}

	loggedIn() {
		const sub = this.user
			.isSignedIn()
			.pipe(
				catchError((err) => {
					this.core.presentToast('Somthing went wrong');

					return EMPTY;
				})
			)
			.subscribe((signedIn) => {
				if (signedIn) {
					if (!document.cookie) {
						const s = this.user
							.getCookie()
							.pipe(
								catchError((err) => {
									this.core.presentToast('Somthing went wrong');

									return EMPTY;
								})
							)
							.subscribe((cookie) => {
								document.cookie = cookie;
								this.router.navigate(['app'], { replaceUrl: true });
							});

						this.subscriptions$.push(s);
					} else {
						const navigation = performance.getEntriesByType(
							'navigation'
						)[0] as any;

						if (navigation.type !== 'reload') {
							this.router.navigate(['app'], { replaceUrl: true });
						}
					}
				}
			});

		this.subscriptions$.push(sub);
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.loggedIn();
			this.loadDefaultSettings();
			this.getNPushNotifications();

			if (this.platform.is('capacitor')) {
				window.screen.orientation.lock('portrait');
			}
		});
	}

	getNPushNotifications() {
		const sub = this.user.getUser().subscribe((user) => {
			if (user?.completedProfile) {
				this.notification.initPush();
			}
		});

		this.subscriptions$.push(sub);
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((sub) => {
			sub.unsubscribe();
		});
	}
}
