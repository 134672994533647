import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './core/services/auth.guard';
import { NavigationGuard } from './core/services/navigation.guard';
import { RequestInterceptorService } from './core/services/request-interceptor.service';

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		CoreModule,
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		TranslateModule.forRoot({
			defaultLanguage: 'fr',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		IonicStorageModule.forRoot(),
	],
	providers: [
		AuthGuard,
		NavigationGuard,
		ImagePicker,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptorService,
			multi: true,
		},
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
