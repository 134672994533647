import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { SplashScreen } from '@capacitor/splash-screen';

@Injectable({
	providedIn: 'root',
})
export class CoreService {
	public swipe: Subject<any> = new Subject<any>();
	public refreshMessageBadgeCount: Subject<any> = new Subject<any>();
	private hideTabBarPages: string[] = ['chat', 'settings', 'edit', 'detail'];
	private routeParamPages: string[] = ['product-details'];
	private showProgressBarPages: string[] = [
		'agreement',
		'user-info',
		'photo',
		'welcome',
	];

	private toastDuration: number = environment.toastDuration;

	private emailRegex =
		/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/ ||
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	private validEmail: RegExp = new RegExp(this.emailRegex);
	private subscriptions$: any[] = [];
	constructor(
		private router: Router,
		private platform: Platform,
		private toastCtrl: ToastController,
		private loaderCtrl: LoadingController,
		private user: UserService
	) {
		this.platform.ready().then(() => {
			this.navEvents();
		});
	}

	validateEmail() {
		return this.validEmail;
	}

	async presentToast(message: string, color: string = 'danger') {
		const toast = await this.toastCtrl.create({
			message: message,
			duration: this.toastDuration,
			color: color,
			cssClass: 'toast-theme',
		});
		toast.present();

		const sub = this.user
			.getHaptics()
			.pipe()
			.subscribe(async (isHaptics) => {
				if (isHaptics) {
					import('@capacitor/haptics').then(
						async ({ Haptics, NotificationType }) => {
							switch (color) {
								case 'danger':
									await Haptics.notification({
										type: NotificationType.Error,
									});
									break;
								case 'success':
									await Haptics.notification({
										type: NotificationType.Success,
									});
									break;
								case 'warning':
									await Haptics.notification({
										type: NotificationType.Warning,
									});
									break;

								default:
									await Haptics.notification({ type: NotificationType.Error });
									break;
							}
						}
					);
				}
			});

		this.subscriptions$.push(sub);
	}

	async presentLoader(showBackdrop: boolean = true) {
		const loader = await this.loaderCtrl.create({
			spinner: 'bubbles',
			cssClass: 'loader-theme',
			translucent: true,
			duration: 10000,
			showBackdrop: showBackdrop,
		});
		await loader.present();
		return await loader;
	}

	private navEvents() {
		const sub = this.router.events
			.pipe(filter((e) => e instanceof NavigationEnd))
			.subscribe((e: any) => {
				this.showHideTabs(e.url);

				setTimeout(() => {
					SplashScreen.hide();
				}, 300);
			});

		this.subscriptions$.push(sub);
	}
	private showHideTabs(url: string) {
		const urlArray = url.split('/');
		const pageUrlParent = urlArray[urlArray.length - 2];
		// Grab the last page url.
		const pageUrl = urlArray[urlArray.length - 1];
		// Result: new-group?type=group
		const page = pageUrl.split('?')[0];
		// Result: new-group
		// Check if it's a routeParamPage that we need to hide on
		const hideParamPage =
			this.routeParamPages.indexOf(pageUrlParent) > -1 && !isNaN(Number(page));
		// Check if we should hide or show tabs.
		const shouldHide = this.hideTabBarPages.indexOf(page) > -1 || hideParamPage;
		// Result: true
		try {
			shouldHide ? this.hideTabs() : this.showTabs();
			// setTimeout(() => (shouldHide ? this.hideTabs() : this.showTabs()), 0);
		} catch (err) {}

		const showProgressBar = this.showProgressBarPages.indexOf(page) > -1;

		try {
			showProgressBar ? this.showProgressBar() : this.hideProgressBar();
		} catch (err) {}
	}
	hideTabs() {
		const tabBar = document.getElementById('tabBar');
		if (tabBar) {
			tabBar.classList.add('hide-tab');
		}
	}

	showTabs() {
		const tabBar = document.getElementById('tabBar');
		if (tabBar) {
			tabBar.classList.remove('hide-tab');
		}
	}

	showProgressBar() {
		const progressBar = document.getElementById('signup-progress-bar');
		if (progressBar) {
			progressBar.classList.remove('hide-bar');
		}
	}

	hideProgressBar() {
		const progressBar = document.getElementById('signup-progress-bar');
		if (progressBar) {
			progressBar.classList.add('hide-bar');
		}
	}

	updateProgressBar(value: number) {
		document
			.getElementById('signup-progress-bar')
			.setAttribute('value', value.toString());
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((sub) => {
			sub.unsubscribe();
		});
	}
}
