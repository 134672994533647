import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SortablejsModule } from 'ngx-sortablejs';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		SortablejsModule.forRoot({ animation: 150 }),
	],
})
export class CoreModule {}
